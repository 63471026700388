import * as React from "react"
import Layout from '../components/layout'

const AboutPage = () => {
    return (<Layout pageHeading='About Us' pageTitle='About Us'>
    Experts in Automation
    </Layout>)
}

export default AboutPage
